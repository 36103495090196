import React from 'react';

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  const handleClickOutside = (e) => {
    if (e.target.id === 'modal-background') {
      onClose();
    }
  };

  return (
    <div
      id="modal-background"
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleClickOutside}
    >
      <div
        className="bg-white p-8 rounded-lg shadow-lg max-w-4xl w-full max-h-[90%] overflow-y-auto my-8"
        style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }} // Hide scrollbar for Firefox and IE
      >
        <div style={{ overflowY: 'scroll', height: '100%', paddingRight: '16px' }}> 
          <style jsx>{`
            ::-webkit-scrollbar {
              display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
            }
          `}</style>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Modal;
