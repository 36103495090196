import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-md">
      <h1 className="text-4xl font-bold mb-4">SlicedBot Privacy Policy</h1>

      <h2 className="text-2xl font-bold mt-6 mb-2">1. Introduction</h2>
      <p>
        At Sliced.WTF, we prioritize your privacy and are committed to protecting your personal data. This Privacy Policy outlines how we collect, use, and safeguard your information when you interact with SlicedBot, our TikTok challenge system available through Discord.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">2. Types of Data Collected</h2>
      <p>
        We collect the following types of personal data when you connect your TikTok account to SlicedBot:
        <ul className="list-disc ml-6 mt-2">
          <li>Username</li>
          <li>Open_id (unique identifier for your TikTok account)</li>
          <li>Public video content and associated information, including views, likes, comments, and shares, related to the videos that are part of the challenges</li>
        </ul>
        All data collected is necessary for us to provide our services, and failure to provide it may prevent us from doing so.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">3. Data Collection and Processing</h2>
      <p>
        The data we collect is processed using computers and IT-enabled tools following organizational procedures closely related to the purposes described below:
        <ul className="list-disc ml-6 mt-2">
          <li>To manage and analyze your participation in challenges, including tracking your performance and engagement with TikTok content</li>
          <li>To help users understand how their videos perform on TikTok, including insights into how they went viral or how engagement can be improved</li>
          <li>To facilitate challenges that allow users to test their skills, challenge themselves, and improve their TikTok presence and content strategy</li>
        </ul>
        The data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">4. Data Retention</h2>
      <p>
        Personal data will be processed and stored for a maximum of 30 days from the date of collection. This period is used to analyze your TikTok performance and provide feedback. After 30 days, all data will be permanently deleted from our systems unless required longer for legal compliance or sponsored challenge purposes. Users can request earlier deletion by disconnecting their TikTok account from SlicedBot.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">5. User Rights</h2>
      <p>
        Users have the right to:
        <ul className="list-disc ml-6 mt-2">
          <li>Withdraw their consent at any time</li>
          <li>Object to data processing</li>
          <li>Access their data and request rectification</li>
          <li>Request data deletion</li>
          <li>Receive their data in a structured, commonly used format</li>
          <li>Lodge a complaint with their data protection authority</li>
        </ul>
        Any requests to exercise user rights can be directed to the Owner through the contact details provided in this document.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">6. Data Security</h2>
      <p>
        We implement industry-standard security measures to protect your data, including encryption and secure storage in a non-publicly accessible database. Access to your data is restricted to authorized personnel who manage and operate the challenge system.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">7. Data Transfers</h2>
      <p>
        Depending on the user's location, data transfers may involve transferring the user's data to a country other than their own. We ensure that appropriate safeguards are in place to protect your data during such transfers.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy to reflect changes in our data practices or legal obligations. If significant changes are made, we will notify you through the Discord platform or by other appropriate means. Your continued use of SlicedBot after such changes signifies your acceptance of the updated policy.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">9. Contact Information</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or how your data is handled, please contact us at [contact@sliced.wtf].
      </p>
    </div>
  );
}

export default PrivacyPolicy;
