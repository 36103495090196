import React from 'react';

function TermsOfService() {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-md">
      <h1 className="text-4xl font-bold mb-4">SlicedBot Terms of Service</h1>

      <h2 className="text-2xl font-bold mt-6 mb-2">1. Acceptance of Terms</h2>
      <p>
        By connecting your TikTok account to SlicedBot, provided by Sliced.WTF, you agree to abide by these Terms of Service ("Terms"). This agreement governs your access and use of the SlicedBot challenge system and all related services. If you do not agree to these Terms, you may not use SlicedBot.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">2. Service Description</h2>
      <p>
        SlicedBot is designed to enhance your TikTok content creation through participation in challenges. By connecting your TikTok account, you allow us to retrieve video data, engagement metrics, and public information such as your TikTok ID, username, and email solely for the purpose of managing and analyzing challenge participation.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">3. Data Collection and Use</h2>
      <p>
        - <strong>General Challenges:</strong> Your data will be retrieved only when you explicitly agree to participate in a challenge. The data collected is used solely to manage your participation, evaluate your performance, and provide feedback.
        <br />
        - <strong>Sponsored Challenges:</strong> For challenges with potential awards or monetary compensation, by participating, you grant Sliced.WTF rights to your video content for that challenge, including the right to use, distribute, or monetize the content as necessary.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">4. User Content and Intellectual Property</h2>
      <p>
        - <strong>Ownership:</strong> You retain ownership of your TikTok content, except in the case of sponsored challenges, where specific rights are transferred to Sliced.WTF as a condition of participation.
        <br />
        - <strong>License:</strong> By using SlicedBot, you grant Sliced.WTF a worldwide, non-exclusive, royalty-free, transferable license to use, copy, distribute, and display your content as necessary for the operation and promotion of the challenge system. This license is limited to the context of challenges you participate in and does not affect your rights to license your content elsewhere.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">5. Data Security and Retention</h2>
      <p>
        - <strong>Security:</strong> We implement industry-standard security measures to protect your data, including encryption and regular security audits. Data is stored in a non-publicly accessible Cloudflare D1 database.
        <br />
        - <strong>Retention:</strong> Data related to your participation in challenges will be stored for a maximum of 30 days, unless required longer for sponsored challenge purposes or by applicable law. You may request earlier deletion by disconnecting your TikTok account.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">6. User Responsibilities</h2>
      <p>
        - <strong>Compliance:</strong> You are responsible for ensuring that your TikTok content complies with all applicable laws and TikTok's terms of service.
        <br />
        - <strong>Conduct:</strong> You agree not to engage in any harmful, illegal, or disruptive behavior while using SlicedBot. Any violation may result in the termination of your access to SlicedBot.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">7. Limitation of Liability</h2>
      <p>
        Sliced.WTF is not liable for any indirect, incidental, or consequential damages arising from your use of SlicedBot. While we strive to provide a secure and uninterrupted service, we cannot guarantee that SlicedBot will be free from errors, interruptions, or unauthorized access.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">8. Termination of Service</h2>
      <p>
        We reserve the right to terminate or suspend your access to SlicedBot at any time if you violate these Terms or engage in any behavior detrimental to the community or the operation of SlicedBot.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">9. Modifications to Terms</h2>
      <p>
        Sliced.WTF may update these Terms as necessary to reflect changes in our service or applicable laws. We will notify you of significant changes, and your continued use of SlicedBot after such modifications will constitute your acceptance of the updated terms.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">10. Dispute Resolution and Governing Law</h2>
      <p>
        - <strong>Governing Law:</strong> These Terms are governed by and construed in accordance with the laws of the jurisdiction in which Sliced.WTF operates, without regard to its conflict of law principles.
        <br />
        - <strong>Dispute Resolution:</strong> Any disputes arising from these Terms or your use of SlicedBot shall be resolved through binding arbitration in accordance with the rules of [Arbitration Body]. You agree to waive your right to a jury trial or to participate in a class action lawsuit.
      </p>

      <h2 className="text-2xl font-bold mt-6 mb-2">11. Contact Information</h2>
      <p>
        If you have any questions or concerns about these Terms or SlicedBot, please contact us at [contact@sliced.wtf].
      </p>

      <p className="mt-6">
        By using SlicedBot, you confirm that you have read, understood, and agree to these Terms of Service.
      </p>
    </div>
  );
}

export default TermsOfService;
