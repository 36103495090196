import React, { useState, useEffect } from "react";
import "@fontsource/roboto"; // Import Roboto font
import './index.css'; // Import Tailwind CSS
import { BrowserRouter as Router, Route, Routes, useLocation, useParams } from 'react-router-dom';
import TermsAndConditions from './terms';
import PrivacyPolicy from './policy';
import Modal from './Modal';
import Normal from './normal';

function App() {
  const location = useLocation(); // Get the current location
  const params = useParams(); // Get the dynamic route params

  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const openTermsModal = () => setIsTermsOpen(true);
  const closeTermsModal = () => setIsTermsOpen(false);

  const openPrivacyModal = () => setIsPrivacyOpen(true);
  const closePrivacyModal = () => setIsPrivacyOpen(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Parse the query parameters
    const code = queryParams.get('code') || params.code; // Retrieve the 'code' parameter from either the query or the params
    const isDev = queryParams.get('dev') || params.dev; // Check if 'dev' is present
  
    if (code) {
      const baseUrl = isDev ? 'https://ds-dev.sliced.wtf' : 'https://discord.sliced.wtf';
      fetch(`${baseUrl}/auth/${code}`)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          if (data.url) {
            localStorage.setItem('authUrl', data.url); // Save the URL in localStorage
            setLoading(false);
          }
        })
        .catch(error => {
          console.error('Error fetching URL:', error);
          setError('Error obteniendo la autorización. Por favor contacte a soporte.');
          setLoading(false);
        });
    } else {
      setError(null); // No error if there's no code, just grey out the button and display the terms and privacy policy
      setLoading(false);
    }
  }, [location.search, params.code, params.dev]); // Re-run this effect if the search parameters or the route parameters change
  
  const handleConnectClick = () => {
    const storedAuthUrl = localStorage.getItem('authUrl');
    if (storedAuthUrl) {
      window.location.href = storedAuthUrl; // Redirect to the stored TikTok auth URL
    } else {
      console.error("No URL found in localStorage");
      setError("No URL found. Please contact support.");
    }
  };

  return (
    <div className="w-1/2 mx-auto p-4 flex flex-col items-center justify-center h-screen">
      <div className="w-40 h-40 mb-6">  
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 444.5 411.17"
          width="100%"  
          height="100%" 
        >
          <path
            className="cls-4"
            d="m13.508 157.17 399.536-98.505 31.455 127.58L44.963 284.75z"
          />
          <text
            fill="#000"
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "105px",
              fontWeight: 700,
              isolation: "isolate",
            }}
            transform="rotate(-13.85 1076.481 -66.135)"
          >
            <tspan x="0" y="0">
              SLICED
            </tspan>
          </text>
          <path
            className="cls-4"
            d="m-.006 281.229 293.413-72.341 31.455 127.58-293.414 72.34z"
          />
          <text
            fill="#000"
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "112px",
              fontWeight: 700,
              isolation: "isolate",
            }}
            transform="rotate(-13.85 1588.834 27.296)"
          >
            <tspan x="0" y="0">
              .BOT
            </tspan>
          </text>
          <text
            transform="rotate(-13.85 520.619 -1460.379)"
            style={{
              fontFamily: "Roboto, sans-serif",
              fontSize: "60px",
              fontWeight: 500,
              isolation: "isolate",
            }}
          >
            <tspan x="0" y="0">
              ™
            </tspan>
          </text>
        </svg>
      </div>
      <button
        className={`px-4 py-2 text-white rounded ${loading || error || !params.code ? 'bg-gray-300' : 'bg-black'}`}
        onClick={handleConnectClick}
        disabled={loading || error || !params.code}
      >
        Conectar cuenta de TikTok 
      </button>

      {error ? 
        <p className="text-xs text-red-400 mt-4">{error}</p> 
        : 
        <label className="text-xs text-gray-400 mt-4 text-center w-1/4">
          Continuando usted acepta haber leído y declara estar de acuerdo
          con los <span onClick={openTermsModal} className="text-black cursor-pointer">términos</span> y <span onClick={openPrivacyModal} className="text-black cursor-pointer">política de privacidad</span>.
        </label>
      }

      <Modal isOpen={isTermsOpen} onClose={closeTermsModal}>
        <TermsAndConditions />
      </Modal>

      <Modal isOpen={isPrivacyOpen} onClose={closePrivacyModal}>
        <PrivacyPolicy />
      </Modal>
    </div>
  );
}

export default function Wrapper() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Normal />} />
        <Route path="/api/:code" element={<App />} />
        <Route path="/api/" element={<App />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}
