import React from "react";
import "@fontsource/roboto/700.css"; // Import Roboto font with 700 weight
import './index.css'; // Import Tailwind CSS

function Normal() {
  return (
    <div className="w-1/2 mx-auto p-4 flex flex-col items-center justify-center h-screen">
      <a href="mailto:contact@sliced.wtf">
        <div className="w-60 h-60 mb-6">
        <svg      
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 444.46 397.92"
            width="100%"
            height="100%"
        >
        <path class="path-animation" d="M444.46 168.14 413 40.56 13.48 139.07l28.07 113.86L0 263.18l31.46 127.58 293.4-72.35-28.07-113.86z"/>

        <text  
            fill="#000"
            className="text-animation-2"
            transform="rotate(-13.7 545 -1470)"
            style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "100px",
                fontWeight: 500,
                isolation: "isolate",
            }}
        ><tspan x="0" y="0">™</tspan>
        </text>
        <text   
            fill="#000"
            className="text-animation"
            transform="rotate(-13.7 1010 -110)"
            style={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "113px",
                fontWeight: 700,
                isolation: "isolate",
            }}>
            <tspan x="0" y="0">
            SLICED
            </tspan>
        </text>
            <text 
                fill="#000"
                className="text-animation"
                transform="rotate(-14.1 1500 21)"
                style={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "110px",
                    fontWeight: 700,
                    isolation: "isolate",
                }}
            >
        <tspan x="0" y="0">.WTF</tspan></text>
        </svg>


        </div>
      </a>
    </div>
  );
}

export default Normal;
